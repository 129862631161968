import React, { Suspense } from 'react'
import { Message } from '../Message'
import { Image } from '../Image'

export const Cases = () => {
  const arr = new Array(2)
  return (
    <Suspense fallback={null}>
      <Message message={'welcome'} />
      <Image />
    </Suspense>
  )
}
