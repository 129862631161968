import React from 'react'
import { useLoader, useFrame, useThree } from 'react-three-fiber'
import * as THREE from 'three'
import { state } from '../../../store'

export const Image = (props) => {
  const mesh = React.useRef()
  const { size } = useThree()
  const texture = useLoader(
    THREE.TextureLoader,
    'https://source.unsplash.com/collection/1971015/750x1000',
  )
  texture.generateMipmaps = false
  texture.minFilter = THREE.LinearFilter
  texture.needsUpdate = true

  const random = () => Math.random() - 0.5
  const rotationZ = random() * (Math.PI / 35)
  const [rotation, setRotation] = React.useState(new THREE.Euler(0, 0, rotationZ))

  useFrame(() => {
    const mouse = state.mouse.current
    if (true) {
      if (mouse) {
        const x = mouse.x - size.width / 2
        const y = mouse.y - size.height / 2
        const rotationX = (rotation.x += (x / 1000 - rotation.x) * 0.04)
        const rotationY = (rotation.y += (y / 1000 - rotation.y) * 0.04)
        setRotation(new THREE.Euler(rotationX, rotationY, rotationZ))
      }
    }
  })

  return (
    <mesh {...props} rotation={rotation} ref={mesh}>
      <planeBufferGeometry args={[60, 80]} />
      <meshBasicMaterial map={texture} />
    </mesh>
  )
}
