import React from 'react'
import { useThree, useFrame } from 'react-three-fiber'
import * as THREE from 'three'

export const Particles = ({ playing, sound }) => {
  const pointsRef = React.useRef()
  const { scene, mouse } = useThree()

  /// GEOMERY
  var separation = 50
  var amountX = 100
  var amountZ = 100
  var numParticles = amountX * amountZ
  var positions = new Float32Array(numParticles * 3)
  var scales = new Float32Array(numParticles)
  var colors = []
  var i = 0
  var j = 0
  var k = 0
  var count = 0
  var lastAverageFrequency = undefined
  var lastFrequency = undefined
  var frequencyData = []

  // create an AudioAnalyser, passing in the sound and desired fftSize
  var analyser = new THREE.AudioAnalyser(sound, 64)

  /// Particles
  for (var ix = 0; ix < amountX; ix++) {
    for (var iz = 0; iz < amountZ; iz++) {
      positions[i] = ix * separation - (amountX * separation) / 2 // x
      positions[i + 1] = 0 // y
      positions[i + 2] = iz * separation - (amountZ * separation) / 2 // z
      scales[j] = 1

      colors[k] = 0.0
      colors[k + 1] = 0.0
      colors[k + 2] = 1.0
      colors[k + 3] = 1.0

      i += 3
      j++
      k += 4
    }
  }

  var uniforms = THREE.UniformsUtils.merge([
    THREE.UniformsLib['ambient'],
    THREE.UniformsLib['lights'],
  ])
  uniforms.time = { value: 1.0 }

  const shader = {
    uniforms: uniforms,
    vertexShader: `attribute float scale;
			attribute vec4 color;

			varying vec4 vColor;
			void main() {
				vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
				gl_PointSize = scale * (300.0 / - mvPosition.z);
				gl_Position = projectionMatrix * mvPosition;

				vColor = color;
			}`,
    fragmentShader: `precision mediump float;
			precision mediump int;

			uniform float time;

			varying vec4 vColor;
			void main() {
				if (length(gl_PointCoord - vec2(0.5, 0.5)) > 0.475) discard;

				vec4 color = vec4(vColor);
				color.r = sin(time * 0.1);
				color.g = 1.0 - sin(time * 0.1);
				gl_FragColor = color;
			}`,
    lights: true,
  }

  useFrame(() => {
    const particles = pointsRef.current
    if (!particles) {
      return
    }

    var time = performance.now()
    var object = scene.children.find((e) => e.constructor.name === 'Points')
    object.material.uniforms.time.value = time * 0.005

    var positions = particles.geometry.attributes.position.array
    var scales = particles.geometry.attributes.scale.array
    var i = 0
    var j = 0

    if (playing) {
      lastAverageFrequency = Math.floor(analyser.getAverageFrequency())
      frequencyData = analyser.getFrequencyData()
    } else {
      lastFrequency = lastFrequency || 50
      lastAverageFrequency = lastAverageFrequency || 50
    }
    for (var ix = 0; ix < amountX; ix++) {
      if (frequencyData.length > 0)
        lastFrequency = Math.floor(
          frequencyData[Math.floor((ix / frequencyData.length) * 10)],
        )

      for (var iz = 0; iz < amountZ; iz++) {
        positions[i + 1] =
          Math.sin((ix + count) * 0.1) * (20 + mouse.y * 10) +
          (Math.sin((ix + count) * 0.5) * lastAverageFrequency) / 2 +
          Math.sin((iz + count) * 0.4) * (10 + mouse.x * 20) +
          Math.sin((iz + count) * 0.3) * lastFrequency
        scales[j] =
          (Math.sin((ix + count) * 0.1) + 1) * 5 + (Math.sin((iz + count) * 0.5) + 1) * 12

        i += 3
        j++
        k += 1
      }
    }

    particles.geometry.attributes.position.needsUpdate = true
    particles.geometry.attributes.scale.needsUpdate = true
    particles.geometry.attributes.color.needsUpdate = true
    count += 0.05
  })

  return (
    <points ref={pointsRef}>
      <shaderMaterial args={[shader]} />
      <bufferGeometry
        attributes={{
          position: new THREE.BufferAttribute(positions, 3),
          scale: new THREE.BufferAttribute(scales, 1),
          color: new THREE.Uint8BufferAttribute(colors, 4),
        }}
      />
    </points>
  )
}
