import React from 'react'
import s from './App.module.scss'
import { ThreeWrapper } from '../three'
import { ScrollComponent } from '../ScrollComponent'

export const App = () => {
  const [page, setPage] = React.useState('index')

  const transition = () => {
    setPage(page === 'index' ? 'particles' : 'index')
  }

  const wrapperClasses = [s.wrapper]
  if (page === 'particles') {
    wrapperClasses.push(s.particles)
  }

  return (
    <div className={wrapperClasses.join(' ')}>
      <ThreeWrapper page={page} />
      <ScrollComponent />
    </div>
  )
}
