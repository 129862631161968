import React from 'react'
import s from './ScrollComponent.module.scss'
import { state } from '../../store'

export const ScrollComponent = () => {
  const scrollArea = React.useRef()

  React.useEffect(() => {
    setScroll({ scrollTop: scrollArea.current.scrollTop })
  }, [])

  const setScroll = (scrollTop) => {
    state.top.current = scrollTop
  }

  const setMouse = (e) => {
    state.mouse.current = { x: e.clientX, y: e.clientY }
  }

  return (
    <div
      ref={scrollArea}
      onScroll={(e) => setScroll(e.target.scrollTop)}
      className={s.scrollArea}
      onMouseMove={(e) => setMouse(e)}
    >
      <div style={{ height: `${state.pages * 100}vh` }} />
    </div>
  )
}
