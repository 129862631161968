import React from 'react'
import { useFrame } from 'react-three-fiber'
import * as THREE from 'three'

export const PauseButton = ({ callback, playing }) => {
  const ref = React.useRef()
  const [hover, setHover] = React.useState(false)
  const [count, setCount] = React.useState(0)

  React.useEffect(() => {
    if (hover) {
      document.body.style.cursor = 'pointer'
    } else {
      document.body.style.cursor = 'default'
    }
  }, [hover])

  useFrame(() => {
    const button = ref.current
    if (button) {
      button.rotation.x = Math.sin(count) * 0.1
      button.rotation.y = Math.sin(count) * 0.5
      setCount(count + 0.03)
    }
  })

  const shape = new THREE.Shape()
  const radius0 = 5
  const eps = 0.001
  const radius = radius0 - eps
  const width = 20
  const height = 70
  const depth = 20
  const smoothness = 3
  shape.absarc(eps, eps, eps, -Math.PI / 2, -Math.PI, true)
  shape.absarc(eps, height - radius * 2, eps, Math.PI, Math.PI / 2, true)
  shape.absarc(width - radius * 2, height - radius * 2, eps, Math.PI / 2, 0, true)
  shape.absarc(width - radius * 2, eps, eps, 0, -Math.PI / 2, true)
  let geometry = new THREE.ExtrudeBufferGeometry(shape, {
    depth: depth - radius0 * 2,
    bevelEnabled: true,
    bevelSegments: smoothness * 2,
    steps: 1,
    bevelSize: radius,
    bevelThickness: radius0,
    curveSegments: smoothness,
  })
  geometry.center()
  const color = hover ? 'hotpink' : 'orange'

  return (
    <group ref={ref} position={[0, 400, 0]}>
      <mesh
        onClick={() => callback()}
        onPointerOver={() => setHover(true)}
        onPointerOut={() => setHover(false)}
        position={[15, 0, 0]}
        geometry={geometry}
        visible={playing}
      >
        <meshStandardMaterial color={color} />
      </mesh>
      <mesh
        onClick={() => callback()}
        onPointerOver={() => setHover(true)}
        onPointerOut={() => setHover(false)}
        position={[-15, 0, 0]}
        geometry={geometry}
        visible={playing}
      >
        <meshStandardMaterial color={color} />
      </mesh>
    </group>
  )
}
