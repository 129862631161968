import React from 'react'
import { Suspense } from 'react'
import { useLoader } from 'react-three-fiber'
import * as THREE from 'three'
import { Particles } from '../ParticlesComponent'

export const ParticlesWrapper = ({ playing }) => {
  var listener = React.useMemo(() => new THREE.AudioListener(), [])
  const sound = React.useMemo(() => new THREE.Audio(listener), [listener])
  const buffer = useLoader(
    THREE.AudioLoader,
    `${process.env.PUBLIC_URL}/resources/music/hooka.mp3`,
  )
  sound.setBuffer(buffer)
  sound.setLoop(true)
  sound.setVolume(0.5)

  React.useEffect(() => {
    if (playing && !sound.isPlaying) {
      sound.play()
    } else {
      sound.pause()
    }
  }, [playing, sound])

  return (
    <Suspense fallback={null}>
      <Particles playing={playing} sound={sound} />
    </Suspense>
  )
}
