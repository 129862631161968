import React from 'react'
import { useLoader, useFrame } from 'react-three-fiber'
import * as THREE from 'three'

const getText = (font, message) => {
  var shapes = font.generateShapes(message, 10)
  var geometry = new THREE.ShapeBufferGeometry(shapes)

  geometry.computeBoundingBox()

  var width = geometry.boundingBox.max.x - geometry.boundingBox.min.x
  var height = geometry.boundingBox.max.y - geometry.boundingBox.min.y
  var xMid = -0.5 * width
  var yMid = -0.5 * height
  geometry.translate(xMid, yMid, 0)

  return { shapes: shapes, width }
}

const getWidth = (text) => {
  if (!text) {
    return 0
  }
  if (typeof text === 'number') {
    return text
  }
  text.geometry.computeBoundingBox()
  return text.geometry.boundingBox.max.x - text.geometry.boundingBox.min.x
}

export const Message = ({ message }) => {
  const ref = React.useRef()
  const font = useLoader(
    THREE.FontLoader,
    `${process.env.PUBLIC_URL}/resources/fonts/Arapey_Regular.json`,
  )
  const material = {
    color: 0xeeeeee,
    transparent: true,
    opacity: 0.5,
    blending: THREE.MultiplyBlending,
    blendEquation: THREE.AddEquation,
    blendSrc: THREE.OneMinusDstColorFactor,
    blendDst: THREE.OneFactor,
  }
  const texts = []
  for (let i = 0; i < 4; i++) {
    texts.push(getText(font, message))
  }
  useFrame(() => {
    if (!ref.current) {
      return
    }
    const offset = 20
    ref.current.children.forEach((child) => {
      if (child.position.x < -message.length * offset) {
        child.position.x = texts.length * getWidth(child) + offset + child.position.x
      }
      child.position.x -= 0.1
    })
  })

  return (
    <group ref={ref}>
      {texts.map((text, i) => (
        <mesh position={[(text.width + 5) * i, -10, 30]} key={i}>
          <shapeBufferGeometry args={[text.shapes]} />
          <meshBasicMaterial args={[material]} />
        </mesh>
      ))}
    </group>
  )
}
