import React, { Suspense } from 'react'
import { Canvas } from 'react-three-fiber'
import { Camera, Cases, ParticlesWrapper, PlayButton, PauseButton } from '..'

export const ThreeWrapper = ({ page }) => {
  const [playing, setPlaying] = React.useState(false)

  const togglePlay = () => {
    setPlaying(!playing)
  }
  return (
    <Canvas
      colorManagement={false}
      gl={{
        antialias: true,
        devicePixelRatio: window.devicePixelRatio || 1,
        preserveDrawingBuffer: true,
      }}
    >
      <Camera animate={page === 'index'} />
      {page === 'index' ? (
        <Cases />
      ) : (
        <>
          <pointLight position={[0, 800, 200]} />
          <ambientLight />
          {playing ? (
            <PauseButton callback={togglePlay} playing={playing} />
          ) : (
            <PlayButton callback={togglePlay} playing={playing} />
          )}
          <Suspense fallback={null}>
            <ParticlesWrapper playing={playing} />
          </Suspense>
        </>
      )}
    </Canvas>
  )
}
