import React from 'react'
import { useFrame, useThree } from 'react-three-fiber'
import { state } from '../../../store'

export const Camera = ({ animate, ...props }) => {
  const ref = React.useRef()
  const mouseRef = React.useRef()
  const { setDefaultCamera, size, scene } = useThree()
  const aspectRatio = size.width / size.height
  const fieldOfView = 20
  const nearPlane = 1
  const farPlane = 10000
  const closePos = [0, 0, 120]
  const farPos = [0, 500, 1000]

  React.useEffect(() => {
    void setDefaultCamera(ref.current)
    window.addEventListener('resize', resize)
    window.addEventListener('mousemove', setMouse)

    return () => {
      window.removeEventListener('resize', resize)
      window.removeEventListener('mousemove', setMouse)
    }
  })

  const resize = () => {
    const camera = ref.current
    camera.aspect = size.width / size.height
    camera.updateProjectionMatrix()
  }

  const setMouse = (e) => {
    mouseRef.current = { x: e.x - size.width / 2, y: e.y - size.height / 2 }
  }

  useFrame(() => {
    const camera = ref.current
    const mouse = state.mouse.current
    if (animate) {
      if (false) {
        const x = mouse.x - size.width / 2
        const y = mouse.y - size.height / 2
        camera.position.x += (x / 40 - camera.position.x) * 0.04
        camera.position.y += (-y / 40 - camera.position.y) * 0.04
      }
      camera.lookAt(scene.position)
    } else {
      camera.lookAt(0, 200, 0)
    }
  })

  return (
    <perspectiveCamera
      ref={ref}
      aspectRatio={aspectRatio}
      fieldOfView={fieldOfView}
      nearPlane={nearPlane}
      farplane={farPlane}
      position={animate ? closePos : farPos}
      rotation={[0, 0, 0]}
      {...props}
    />
  )
}
